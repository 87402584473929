<template>
  <div class="row justify-center" style="margin-bottom: 100px;">
    <div class="col-7">
      <q-breadcrumbs class="q-pb-md cursor" separator=">" @click="goBack">
        <q-breadcrumbs-el icon="chevron_left" label="返回上级" />
      </q-breadcrumbs>
    </div>
    <q-card class="col-7">
      <q-img :src="productData.img.length === 0 ? '' : 'https://r.xjip.info/media' + productData.img[0]" style="height: 300px; width: 250x"></q-img>
      <q-table
        :title="productData.name + ' 地标原产地特产企业名录'"
        :data="list"
        :columns="columns"
        no-data-label="抱歉，没有为您查找到相关数据"
        rows-per-page-label="每页记录条数:"
        row-key="id"
      />
      <q-inner-loading :showing="isLoading">
      </q-inner-loading>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      breadList: [],
      columns: [
        {
          name: "enterprise",
          label: "企业",
          align: "center",
          field: "enterprise"
        },
        {
          name: "brand",
          label: "商标",
          align: "center",
          field: "brand"
        },
        {
          name: "license",
          label: "核准许可文件",
          align: "center",
          field: "license"
        },
        {
          name: "term",
          label: "有效期",
          align: "center",
          field: "term"
        },
        {
          name: "remarks",
          label: "备注",
          align: "center",
          field: "remarks"
        }
      ],
      product: "数据为空",
      productData: {img: [], name: ''},
      list: [],
      isLoading: true
    };
  },
  mounted() {
    this.product = this.$route.params.id;
    this.breadList = this.$route.matched;
    this.getProduct();
  },
  methods: {
    getProduct() {
      this.isLoading = true;
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "/landmark/product?id=" +
            this.product
        )
        .then(res => {
          this.isLoading = false
          if (res.data.code === 20000) {
            this.list = res.data.data.publicityData
            this.productData = res.data.data.landmark
          }
        })
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor:hover {
  cursor: pointer;
}
</style>
